import React, { useEffect, useState } from 'react'
import iframeRotateOverlay from '../../../assets/icons/iframe_rotate_overlay.svg'
import './Intro.scss'

const Intro = () => {
  const [domLoaded, setDomLoaded] = useState(false)
  const [showRotateOverlay, setShowRotateOverlay] = useState(true)
  useEffect(() => {
    window.addEventListener('DomContentLoaded', setDomLoaded(true))
  }, [])

  return (
    <section className='intro_section'>
      {/* <video autoPlay muted loop className="intro_video">
        <source
          src="https://css-tricks-post-videos.s3.us-east-1.amazonaws.com/Island%20-%204141.mp4"
          type="video/mp4"
        />
      </video>
      <div className="intro_titles_container">
        
          <h1
            className={`intro_title ${domLoaded && "intro_title_animation"}`}
          >
            SPINABLE
          </h1>
          <h3
            className={`intro_subtitle ${
              domLoaded && "intro_subtitle_animation"
            }`}
          >
            360' Product Photography For The Next Generation E-Commerce
          </h3>
      </div> */}

      <div className='intro_container'>
        <div className='intro_section_inner'>
          <div className='row'>
            <div className='col-md-7'>
              <div className='intro_titles_container'>
                <div className='intro_title_wrapper'>
                  <h1
                    className={`intro_title ${
                      domLoaded && 'intro_title_animation'
                    }`}
                  >
                    SPINABLE
                  </h1>
                  <h3
                    className={`intro_subtitle ${
                      domLoaded && 'intro_subtitle_animation'
                    }`}
                  >
                    360° Product Photography For The Next <br className='break' /> Generation E-Commerce
                  </h3>
                </div>
              </div>
            </div>
            <div className='col-md-5 img_360_container'>
              <div
                className='img_360_wrapper'
                onMouseEnter={() => setShowRotateOverlay(false)}
                onMouseLeave={() => setShowRotateOverlay(true)}
                onTouchStart={() => setShowRotateOverlay(false)}
                onTouchMove={() => setShowRotateOverlay(false)}
              >
                <img
                  className={`overlay_before_hover ${
                    !showRotateOverlay && 'overlay_after_hover'
                  }`}
                  src={iframeRotateOverlay}
                  alt=''
                />
                <iframe
                className="img_360_iframe"
                  src='https://v1.pixriot.com?spin=4558637868/Website_Contents/Spinable Revised Home 360&t=1645201177'
                  style={{ width: '100%', border: 'none' }}
                  height='500'
                  title='360-image'
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
