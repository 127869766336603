import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import arrowDown from "../../../assets/icons/sales_analysis/arrow_down.svg";
import arrowUp from "../../../assets/icons/sales_analysis/arrow_up.svg";
import arrow from "../../../assets/icons/sales_analysis/sales_arrow.svg";
import "./SalesAnalysis.scss";

const salesData = {
  topRow: [
    {
      id: 1,
      heading: "Product Return Rate",
      rate: "30%",
      arrow: arrowDown
    },
    {
      id: 2,
      heading: "Efficiency Rate",
      rate: "60%",
      arrow: arrowUp
    },
    {
      id: 3,
      heading: "Engagement Rate",
      rate: "45%",
      arrow: arrowUp
    },
  ],
  bottomRow: {
    heading: "Overall Sales Increase",
    rate: 20,
    details: "Through cutting-edge content showcasing, Spinable ensures sales growth with immediate results."
  }
  
}

const SalesAnalysis = () => {
  const [isArrowSaleShown, setIsArrowSaleShown] = useState(true);
  const [isSaleAboutShown, setIsSaleAboutShown] = useState(true);
  const sales_ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [isIncreasePassed, setIsIncreasePassed] = useState(false);

  const handleVisibility = (entries) => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
      if(entry.isIntersecting) setIsIncreasePassed(true);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver(handleVisibility, options);
    if (sales_ref.current) observer.observe(sales_ref.current);

    return () => {
      if (sales_ref.current) observer.unobserve(sales_ref.current);
    };
  }, [sales_ref]);

  const SalesRateSingle = ({salesRateSingle}) => {
    return (
      <div className="col-md-4">
        <div className="top_row_container">
          <h3>{salesRateSingle.heading}</h3>
          <h2> <img src={salesRateSingle.arrow} alt="arrow"/> {salesRateSingle.rate}</h2>
        </div>
      </div>
    )
  }

  // const getSalesSectionData = () => {
  //   if (sales_ref.current.getBoundingClientRect().top < 450)
  //     setIsArrowSaleShown(true);

  //   if (sales_ref.current.getBoundingClientRect().top < 500)
  //     setIsSaleAboutShown(true);
  // };

  // useEffect(() => {
  //   if (!isArrowSaleShown || !isSaleAboutShown) {
  //     if (window.innerWidth > 767.98) {
  //       window.addEventListener("wheel", getSalesSectionData);
  //     } else {
  //       window.addEventListener("scroll", getSalesSectionData);
  //     }
  //   }
  //   return () => {
  //     window.removeEventListener("wheel", getSalesSectionData);
  //     window.removeEventListener("scroll", getSalesSectionData);
  //   };
  // }, [isArrowSaleShown, isSaleAboutShown]);
  return (
    <section className="sales_analysis_section">
      <div className="container_custom">
        <div className="sales_analysis_container">
          <div className="row">
            {
              salesData.topRow.map((salesRateSingle) => <SalesRateSingle key={salesRateSingle.id} salesRateSingle={salesRateSingle} />)
            }
          </div>

          <div ref={sales_ref} className="sales_increase_container">
            <h3 className="sales_increase_title" >{salesData.bottomRow.heading}</h3>
            <div
              className={`sales_increase_arrow ${
                (isVisible || isIncreasePassed) && "sales_increase_arrow_animation"
              }`}
            >
              <img src={arrow} alt="sales_increase_arrow" />
            </div>
            <div className="sales_increase_texts">
              <h1
                className={`sales_increase_title ${
                  isArrowSaleShown && "sales_increase_title_animation"
                }`}
              >
                {isVisible || isIncreasePassed ? <CountUp start={0} end={salesData.bottomRow.rate} duration={1} suffix='%' /> : "0%"}
                
              </h1>
              <h3
                className={`sales_increase_about ${
                  isSaleAboutShown && "sales_increase_about_animation"
                }`}
              >
                {salesData.bottomRow.details}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SalesAnalysis;
