import Hamburger from 'hamburger-react'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import spinableLogoBlack from '../../assets/logo/spinable-black-logo.svg'
import spinableLogoWhite from '../../assets/logo/spinable-white-logo.svg'
import './HamburgerMenu.scss'

const hamburgerMenuContents = [
  {
    id: 0,
    name: 'Home',
  },
  {
    id: 1,
    name: 'Introduction',
  },
  {
    id: 2,
    name: 'Sales Analysis',
  },
  {
    id: 3,
    name: 'Our Services',
  },
  {
    id: 4,
    name: 'Key Features',
  },
  {
    id: 5,
    name: 'Demo 360',
  },
  {
    id: 6,
    name: 'Contact Us',
  },
]

const HamburgerMenu = ({ inHomePage, handleSetScrollSectionNum }) => {
  const [isOpen, setOpen] = useState(false)
  const [showHamburgerBg, setShowHamburgerBg] = useState(false)
  const navigate = useNavigate()
  const handleMenuClick = (sectionNum) => {
    navigate('/')
    handleSetScrollSectionNum(sectionNum)
    setOpen(false)
  }

  const handleHamburgerBg = () => {
    if (window.scrollY >= 80) {
      setShowHamburgerBg(true)
    } else {
      setShowHamburgerBg(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleHamburgerBg)
    return () => {
      window.removeEventListener('scroll', handleHamburgerBg)
    }
  }, [])

  const HamburgerMenuSingle = ({ hamburgerMenuSingle }) => {
    return (
      <div className='hamburger_menu_single'>
        <button onClick={() => handleMenuClick(hamburgerMenuSingle.id)}>
          {hamburgerMenuSingle.name}
        </button>
      </div>
    )
  }
  return (
    <>
      <div
        className={`hamburger_container ${
          showHamburgerBg && 'hamburger_container_scroll'
        }`}
      >
        <div className='hamburger_logo'>
          <Link to='/'>
            <img
              src={
                inHomePage
                  ? showHamburgerBg
                    ? spinableLogoWhite
                    : spinableLogoBlack
                  : spinableLogoWhite
              }
              alt='spinable-logo'
            />
          </Link>
        </div>
        <div
          className={`hamburger_circle ${
            isOpen ? 'hamburger_circle_black' : 'hamburger_circle_white'
          }`}
        >
          <Hamburger
            label='Show menu'
            toggled={isOpen}
            toggle={setOpen}
            size={25}
            color={`${isOpen ? '#fff' : '#000'}`}
            rounded
          />
        </div>
        <div
          className={`hamburger_underlay ${
            isOpen && 'hamburger_underlay_animation'
          }`}
        ></div>
      </div>
      <div className='hamburger_menu_container'>
        <div
          className={`hamburger_menu_wrapper ${
            isOpen && 'hamburger_menu_wrapper_animation'
          }`}
        >
          {hamburgerMenuContents.map((hamburgerMenuSingle) => (
            <HamburgerMenuSingle
              key={hamburgerMenuSingle.id}
              hamburgerMenuSingle={hamburgerMenuSingle}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default HamburgerMenu
