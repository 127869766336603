import parse from 'html-react-parser'
import React from 'react'
import basicPhotoshootIcon from '../../../assets/icons/services/basic_photoshoot.svg'
import photoshootCreativeDesignIcon from '../../../assets/icons/services/photoshoot_creative_design.svg'
import photoshootProIcon from '../../../assets/icons/services/photoshoot_pro.svg'
import photoshootRetouchIcon from '../../../assets/icons/services/photoshoot_retouch.svg'
import './Services.scss'

const services = [
  {
    id: 1,
    heading: `<h3>Basic <br/> Photoshoot</h3>`,
    subHeading: 'Capture anything to give a better view to your audience',
    fee: '499 BDT',
    details: [
      {
        id: 11,
        text: '360 Photoshoot',
      },
      {
        id: 12,
        text: 'Photo Compilation',
      },
      {
        id: 13,
        text: 'Server Upload Ready',
      },
      {
        id: 14,
        text: 'Cloud Storage',
      },
    ],
    icon: basicPhotoshootIcon,
  },
  {
    id: 2,
    heading: `<h3>Photoshoot <br/> & Retouch</h3>`,
    subHeading:
      'Increase the attractiveness and quality of your captured photos',
    fee: '1499 BDT',
    details: [
      {
        id: 21,
        text: '360 Photoshoot',
      },
      {
        id: 22,
        text: 'Photo Retouch',
      },
      {
        id: 23,
        text: 'Photo Compilation',
      },
      {
        id: 24,
        text: 'Server Upload Ready',
      },
      {
        id: 25,
        text: 'Cloud Storage',
      },
    ],
    icon: photoshootRetouchIcon,
  },
  {
    id: 3,
    heading: `<h3>Photoshoot <br/> & Creative Design</h3>`,
    subHeading:
      'Add a strong impression by giving a better clarification and building customer trust',
    fee: 'Custom Price',
    details: [
      {
        id: 31,
        text: '360 Photoshoot',
      },
      {
        id: 32,
        text: 'Photo Retouch',
      },
      {
        id: 33,
        text: 'Clipping Path',
      },
      {
        id: 34,
        text: 'Creative Design',
      },
      {
        id: 35,
        text: 'Photo Compilation',
      },
      {
        id: 36,
        text: 'Stock Sound Mix',
      },
      {
        id: 37,
        text: 'Video/GIF Output',
      },
      {
        id: 38,
        text: 'Social Media Ready',
      },
    ],
    icon: photoshootCreativeDesignIcon,
  },
  {
    id: 4,
    heading: `<h3>Photoshoot <br/> & Pro</h3>`,
    subHeading:
      'Add a strong impression by giving a better clarification and building customer trust',
    fee: 'Custom Price',
    details: [
      {
        id: 41,
        text: '360 Photoshoot',
      },
      {
        id: 42,
        text: 'Photo Retouch',
      },
      {
        id: 43,
        text: 'Clipping Path',
      },
      {
        id: 44,
        text: 'Creative Design',
      },
      {
        id: 45,
        text: 'Photo Compilation',
      },
      {
        id: 46,
        text: 'Stock Sound Mix',
      },
      {
        id: 47,
        text: 'Video/GIF Output',
      },
      {
        id: 48,
        text: 'Server Upload Ready',
      },
      {
        id: 49,
        text: 'Cloud Storage',
      },
    ],
    icon: photoshootProIcon,
  },
]

const Services = ({ contactScroll }) => {
  const Service = ({ service }) => {
    const handleBtnClick = (e) => {
      e.preventDefault()
      contactScroll()
    }
    return (
      <div className='col-xl-3 col-md-6'>
        <div className='service_wrapper'>
          <img src={service.icon} alt='' />
          <div className='service_texts_wrapper'>
            <h3>{parse(service.heading)}</h3>
            <p className='service_subheading'>{service.subHeading}</p>
            <div className="details-wrapper">
              <ul>
                {service.details.map((detail) => (
                  <li key={detail.id}>{detail.text}</li>
                ))}
              </ul>
            </div>

            <button onClick={handleBtnClick}>Learn More</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className='services_section'>
      <div className='container_custom'>
        <div className='services_container'>
          <h2>Services</h2>
          <div className='services_wrapper'>
            <div className='row'>
              {services.map((service) => (
                <Service key={service.id} service={service} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
