import React from 'react';
import three60ViewAngleIcon from "../../../assets/icons/features/360_view_angle.svg";
import automatedFuncIcon from "../../../assets/icons/features/automated_func.svg";
import efficientWorkflowIcon from "../../../assets/icons/features/efficient_workflow.svg";
import industryLeadingEditsIcon from "../../../assets/icons/features/industry_leading_edits.svg";
import interactiveExpIcon from "../../../assets/icons/features/interactive_exp.svg";
import preciseCaptures2Icon from "../../../assets/icons/features/precise_captures_2.svg";
import './Features.scss';

const features = [
  {
    id: 1,
    img: automatedFuncIcon,
    heading: 'Automated Functions',
  },
  {
    id: 2,
    img: preciseCaptures2Icon,
    heading: 'Precise Captures',
  },
  {
    id: 3,
    img: efficientWorkflowIcon,
    heading: 'Efficient Workflow',
  },
  {
    id: 4,
    img: three60ViewAngleIcon,
    heading: '360’ Viewing Angles',
  },
  {
    id: 5,
    img: interactiveExpIcon,
    heading: 'Interactive Experience',
  },
  {
    id: 6,
    img: industryLeadingEditsIcon,
    heading: 'Industry-Leading Edits',
  },
]

const Features = () => {
  const Feature = ({ feature }) => {
    return (
      <div className='col-6 col-md-4'>
        <div className='feature_wrapper'>
          <img src={feature.img} alt='' />
          <div className='feature_texts_wrapper'>
            <h3>{feature.heading}</h3>
          </div>
        </div>
      </div>
    )
  }
  return (
    <section className='features_section'>
      <div className='container_custom'>
        <div className='features_container'>
          <h2>Features</h2>
          <div className='features_wrapper'>
            <div className='row'>
              {features.map((feature) => (
                <Feature key={feature.id} feature={feature} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
