import emailjs from '@emailjs/browser'
import React, { useRef } from 'react'
import spinableIcon from '../../../assets/logo/spinable-icon.png'
import './Contact.scss'

const Contact = ({handleSetShowLoader}) => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault()
    handleSetShowLoader(true)

    emailjs
      .sendForm(
        'service_vvgovnm',
        'template_t99m53r',
        e.target,
        'user_0J1mOpFYO90dBFi9spil9'
      )
      .then(
        (result) => {
          console.log(result.text)
          alert("Successfully submitted!");
          handleSetShowLoader(false);
          e.target.reset();
        },
        (error) => {
          console.log(error.text)
          alert("Error, try again!");
          handleSetShowLoader(false);
        }
      )
  }
  return (
    <section className='footer_section'>
      <hr className='hor_line' />
      <div className='container_custom'>
        <div className='footer_container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='footer_info_container'>
                <img src={spinableIcon} alt='chobir_baksho' />
                <h2>
                  Want to say hello? Want to know more about us? Give us a call
                  or drop us an email and we will get back to you as soon as we
                  can.
                </h2>
                <div className='footer_contact_wrapper'>
                  <div className='footer_contact_single'>
                    <h2>01704244466</h2>
                  </div>

                  <div className='footer_contact_single'>
                    <h2>House 40, Road 5, Block G, Banani, Dhaka 1213, Bangladesh</h2>
                  </div>

                  <div className='footer_contact_single'>
                  <a href = "mailto: info@spinable.io">
                      info@spinable.io
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <form className='footer_form' ref={form} onSubmit={sendEmail}>
                <div className='mb-3'>
                  <input
                    type='name'
                    name='name'
                    className='footer_input'
                    id='exampleInputName1'
                    aria-describedby='nameHelp'
                    placeholder='Your name'
                    required
                  />
                </div>
                <div className='mb-3'>
                  <input
                    type='email'
                    name='email'
                    className='footer_input'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Your email'
                    required
                  />
                </div>
                <div className='mb-3'>
                  <input
                    type='text'
                    name='phoneNumber'
                    className='footer_input'
                    id='exampleInputSubject1'
                    aria-describedby='phoneNumberHelp'
                    placeholder='Your Phone Number'
                    required
                  />
                </div>
                <div className='mb-3'>
                  <textarea
                    rows='6'
                    type='message'
                    name='message'
                    className='footer_input'
                    id='exampleInputMessage1'
                    placeholder='Your message'
                    required
                  />
                </div>
                <button type='submit' className='submit_btn'>
                  Submit
                </button>
              </form>
            </div>
          </div>
          <footer>
            <div className='copyright_text'>
              <span>
                © {new Date().getFullYear()} spinable.io | All Rights Reserved.
              </span>
            </div>
          </footer>
        </div>
      </div>
    </section>
  )
}

export default Contact
