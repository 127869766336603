import React from 'react'
import { useNavigate } from 'react-router-dom'
import click360Icon from '../../../assets/icons/demo_photos/click_360.svg'
import innisfreeImg from '../../../assets/images/demo_photos/innisfree.png'
import mobilePhoneImg from '../../../assets/images/demo_photos/mobile_phone.png'
import monsterTruckImg from '../../../assets/images/demo_photos/monster_truck.png'
import shoeImg from '../../../assets/images/demo_photos/shoe.png'
import vrImg from '../../../assets/images/demo_photos/vr.png'
import watchImg from '../../../assets/images/demo_photos/watch.png'
import './Photos.scss'

const photos = [
  {
    id: 0,
    name: "innisfree",
    photo: innisfreeImg,
  },
  {
    id: 1,
    name: "mobile-phone",
    photo: mobilePhoneImg,
  },
  {
    id: 2,
    name: "monster-truck",
    photo: monsterTruckImg,
  },
  {
    id: 3,
    name: "shoe",
    photo: shoeImg,
  },
  {
    id: 4,
    name: "vr",
    photo: vrImg,
  },
  {
    id: 5,
    name: "watch",
    photo: watchImg,
  },
]

const Photos = () => {
  const navigate = useNavigate()
  const GoToDemo360 = (photoName) => {
    navigate(`/demo360/${photoName}`)
  }
  const Photo = ({ photo }) => {
    return (
      <div className='col-md-4 col-6' key={photo.id}>
        <div className='photo_single_wrapper'>
          <div
            className='photo_single_overlay'
            onClick={() => GoToDemo360(photo.name)}
          >
            <img src={click360Icon} alt='view360Img' />
          </div>
          <div
            className='photo_single'
            style={{ backgroundImage: `url(${photo.photo})` }}
          ></div>
        </div>
      </div>
    )
  }
  return (
    <section className='photos_section'>
      <div className='container_custom'>
        <div className='photos_container'>
        <h2>Demo 360</h2>
        <div className="photos_wrapper">
        <div className='row'>
            {photos.map((photo) => (
              <Photo key={photo.id} photo={photo} />
            ))}
          </div>
        </div>
          
        </div>
      </div>
    </section>
  )
}

export default Photos
