import React, { useEffect, useRef } from 'react'
import Contact from '../components/Home/Contact/Contact'
import Features from '../components/Home/Features/Features'
import Info from '../components/Home/Info/Info'
import Intro from '../components/Home/Intro/Intro'
import Photos from '../components/Home/Photos/Photos'
import SalesAnalysis from '../components/Home/SalesAnalysis/SalesAnalysis'
import Services from '../components/Home/Services/Services'

const Home = ({scrollSectionNum, handleSetInHomePage, handleSetShowLoader}) => {
  const introRef = useRef()
  const infoRef = useRef()
  const salesAnalysisRef = useRef()
  const servicesRef = useRef()
  const featuresRef = useRef()
  const photosRef = useRef()
  const contactRef = useRef()

  const introScroll = () => introRef.current.scrollIntoView({ behavior: 'smooth' })
  const infoScroll = () => infoRef.current.scrollIntoView({ behavior: 'smooth' })
  const salesAnalysisScroll = () => salesAnalysisRef.current.scrollIntoView({ behavior: 'smooth' })
  const servicesScroll = () => servicesRef.current.scrollIntoView({ behavior: 'smooth' })
  const featuresScroll = () => featuresRef.current.scrollIntoView({ behavior: 'smooth' })
  const photosScroll = () => photosRef.current.scrollIntoView({ behavior: 'smooth' })
  const contactScroll = () => contactRef.current.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    const sectionScrolls = [
      introScroll,
      infoScroll,
      salesAnalysisScroll,
      servicesScroll,
      featuresScroll,
      photosScroll,
      contactScroll,
    ]
    if(scrollSectionNum || scrollSectionNum === 0) {
      sectionScrolls[scrollSectionNum]();
    }
  }, [scrollSectionNum])

  useEffect(() => {
    handleSetInHomePage(true)
  }, [handleSetInHomePage])
  return (
    <>
      <nav>
        {/* <HamburgerMenu handleSetScrollSection={handleSetScrollSection} /> */}
      </nav>
      <main>
        <section ref={introRef}>
          <Intro />
        </section>
        <section ref={infoRef}>
          <Info />
        </section>
        <section ref={salesAnalysisRef}>
          <SalesAnalysis />
        </section>
        <section ref={servicesRef}>
          <Services contactScroll={contactScroll} />
        </section>
        <section ref={featuresRef}>
          <Features />
        </section>
        <section ref={photosRef}>
          <Photos />
        </section>
      </main>
      <section ref={contactRef}>
        <Contact handleSetShowLoader={handleSetShowLoader} />
      </section>
    </>
  )
}

export default Home
