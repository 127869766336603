import React, { useEffect } from 'react'
import monsterTruckGif from '../../../assets/images/info/monster_truck.gif'
import productPhotoshootGif from '../../../assets/images/info/product_photoshoot.gif'
import websiteGif from '../../../assets/images/info/website_loop.gif'
import './Info.scss'

const infos = [
  {
    id: 1,
    heading: 'Showcase Your Products Like Never Before',
    subHeading:
      "Spinable is a first-of-its-kind photography technology, that let's your consumers view any product in 360'motion.",
    gif: monsterTruckGif,
    marTop: false,
  },
  {
    id: 2,
    heading: 'Automate Your Photoshoots With Maximum Efficiency',
    subHeading:
      'With Spinable, manual image capturing is a thing of the past. With Automated Capture Technology, Spinable ensures 360 image capture precisely under 2 minutes.',
    gif: productPhotoshootGif,
    marTop: true,
  },
  {
    id: 3,
    heading: 'A Complete E-commerce Experience For The Consumers',
    subHeading:
      'Through loads of added features and a contemporary outlook for your business, Spinable reinvents how users interact with your website to bring you a complete E-Commerce Experience.',
    gif: websiteGif,
    marTop: true,
  },
]

const Info = () => {
  useEffect(() => {
    if (window.innerWidth > 767.98) {
      const spaceHolder = document.querySelector('.space-holder')
      const horizontal = document.querySelector('.horizontal')

      const calcDynamicHeight = (ref) => {
        const vw = window.innerWidth
        const vh = window.innerHeight
        const objectWidth = ref.scrollWidth
        return objectWidth - vw + vh
      }

      spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`

      window.addEventListener('scroll', () => {
        const sticky = document.querySelector('.sticky')
        horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`
      })

      window.addEventListener('resize', () => {
        spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`
      })
      return () => {
        window.removeEventListener('scroll', () => {
          const sticky = document.querySelector('.sticky')
          horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`
        })

        window.removeEventListener('resize', () => {
          spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`
        })
      }
    }
  }, [])

  const InfoSingle = ({ info }) => {
    return (
      <div
        className={`info_single ${
          !info.marTop && 'info_single_mar'
        } container_custom`}
      >
        <div className='row'>
          <div className='col-md-6 info_text_container'>
            <div>
              <h2>{info.heading}</h2>
              <p>{info.subHeading}</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='info_img_container'>
              <img className='img-fluid w-100' src={info.gif} alt='info-gif' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className='info_section'>
      <div className='space-holder'>
        <div className='sticky'>
          <div className='horizontal'>
            <div className='info_container'>
              {infos.map((info) => (
                <InfoSingle key={info.id} info={info} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Info
