import { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss'
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu'
import Loader from './components/Loader/Loader'
import ScrollToTop from './helpers/ScrollToTop'
import Demo360 from './screens/Demo360'
import Home from './screens/Home'

const App = () => {
  const [scrollSectionNum, setScrollSectionNum] = useState(null)
  const [inHomePage, setInHomePage] = useState(true)
  const [showLoader, setShowLoader] = useState(false)

  const handleSetInHomePage = (bool) => {
    setInHomePage(bool)
  }

  const handleSetShowLoader = (bool) => {
    setShowLoader(bool)
  }

  const handleSetScrollSectionNum = (sectionNum) => {
    setScrollSectionNum(sectionNum)
  }

  useEffect(() => {
    if (window.innerWidth > 767.98) {
      document.body.style.height = '100%'
    }
  })
  return (
    <BrowserRouter>
      <ScrollToTop />
      {showLoader && <Loader />}
      <HamburgerMenu
        inHomePage={inHomePage}
        handleSetInHomePage={handleSetInHomePage}
        handleSetScrollSectionNum={handleSetScrollSectionNum}
      />
      <Routes>
        <Route
          path='/demo360/:photoName'
          element={<Demo360 handleSetInHomePage={handleSetInHomePage} />}
        />
        <Route
          exact
          path='/'
          element={
            <Home
              handleSetInHomePage={handleSetInHomePage}
              scrollSectionNum={scrollSectionNum}
              handleSetShowLoader={handleSetShowLoader}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
