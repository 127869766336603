import React, { useState } from 'react'
import iframeRotateOverlay from '../../assets/icons/iframe_rotate_overlay.svg'
import './Demo360Inner.scss'

const photos = [
  {
    id: 0,
    name: 'innisfree',
    link: 'https://v1.pixriot.com?spin=4558637868/Website_Contents/Innisfree&t=1645210790',
  },
  {
    id: 1,
    name: 'mobile-phone',
    link: 'https://v1.pixriot.com?spin=4558637868/Website_Contents/Mobile Phone 2&t=1645210813',
  },
  {
    id: 2,
    name: 'monster-truck',
    link: 'https://v1.pixriot.com/?spin=4558637868/Website_Contents/Monster%20Truck&t=1645209538',
  },
  {
    id: 3,
    name: 'shoe',
    link: 'https://v1.pixriot.com?spin=4558637868/Website_Contents/Shoe&t=1645210830',
  },
  {
    id: 4,
    name: 'vr',
    link: 'https://v1.pixriot.com?spin=4558637868/Website_Contents/VR&t=1645210845',
  },
  {
    id: 5,
    name: 'watch',
    link: 'https://v1.pixriot.com?spin=4558637868/Website_Contents/Watch&t=1645210864',
  },
]

const CartInside = ({ photoName }) => {
  const [showRotateOverlay, setShowRotateOverlay] = useState(true)
  const find360Photo = photos.find((photo) => photo.name === photoName)
  return (
    <section className='demo360_section'>
      <div className='container_custom'>
        <div className='demo360_container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='demo360_img_container'>
                <div
                  className='demo_360_img_wrapper'
                  onMouseEnter={() => setShowRotateOverlay(false)}
                  onMouseLeave={() => setShowRotateOverlay(true)}
                  onTouchStart={() => setShowRotateOverlay(false)}
                  onTouchMove={() => setShowRotateOverlay(false)}
                >
                  <img
                    className={`overlay_before_hover ${
                      !showRotateOverlay && 'overlay_after_hover'
                    }`}
                    src={iframeRotateOverlay}
                    alt=''
                  />
                  <iframe
                    src={find360Photo.link}
                    title='360-image'
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='demo360_inside_info'>
                <div className='title'></div>
                <div className='subtitle'></div>
                <div className='price_value_wrapper'>
                  <div className='price_name'></div>
                  <div className='price_value'></div>
                </div>
                <div className='warranty'></div>
                <div className='emi'></div>
                <div className='color_wrapper'>
                  <div className='color_1'></div>
                  <div className='color_2'></div>
                </div>
                <div className='cart_buy_wrapper'>
                  <div className='cart'></div>
                  <div className='buy'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CartInside
