import React, { useEffect } from "react";
import { useParams } from "react-router";
import Demo360Inner from "../components/Demo360/Demo360Inner";
import Contact from "../components/Home/Contact/Contact";

const Demo360 = ({handleSetInHomePage}) => {
  const { photoName } = useParams();

  useEffect(() => {
    handleSetInHomePage(false)
  }, [handleSetInHomePage])
  return (
    <>
      <nav>
        {/* <HamburgerMenu /> */}
      </nav>
      <main>
      <Demo360Inner key={photoName} photoName={photoName} />
      </main>
      <Contact/>
    </>
  );
};

export default Demo360;
